/* You can add global styles to this file, and also import other style files */

@import "bootstrap/bootstrap";
@import "settings";
@import "mixins";
@import "base";
@import "spinner";
@import "rtl";
@import "responsive";
@import "tables";

.modal-dialog {
    max-width: 80%;
}
.visually-hidden{
    display: none;
}
/*
p-inputmask.form-control {
    input.ui-inputtext.ui-corner-all.ui-state-default.ui-widget {
        border: none;
        width: -webkit-stretch;
    }
    .ui-inputtext.ui-state-focus,
    .ui-inputtext:focus {
        box-shadow: none;
        border: none;
    }
    ::-webkit-input-placeholder {
        color: #636c72;
    }
    padding: 0.25rem;
}
*/
