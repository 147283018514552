@import "bootstrap/variables";

$topnav-background-color: #F6F6F6; //#222;
$placeholder-color: #666;
$border: #666;
$header-height: 85px;

.wrapper-app {
  min-height: calc(100vh - #{$header-height});
  background: $topnav-background-color;
  padding: 1rem;
  margin-top: $header-height;

  @media(max-width: 700px) {
    padding: 1rem 0.5rem;
  }

  &--old {
    background: #FFFFFF;
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    text-align: left;
    margin-bottom: 1rem;

    .title {
      margin: 0;
      padding-right: 1rem;

      &--with-button,
      &--with-accounts,
      &--with-year,
      &--with-view-selector{
        line-height: 2.2rem;
      }
    }

    .btn--export{
      margin-left: 1rem;
    }

    .btn + .btn{
      margin-left: 1rem;
    }

    .add-button {
      float: right;
      font-size: 1em;
    }

    .title__dropdown {
      float: right;
      width: 300px;

      .ui-dropdown {
        width: 300px;

        .ui-dropdown-trigger {
          .fa {
            margin-top: 0.6em;
          }
        }
      }

      &--year{
        width: 125px;

        .ui-dropdown{
          width: 125px;
        }
      }
    }
  }

  .description-container{
    font-size: .9375rem;
  }
}

.app-container {
  max-width: 1280px;
  margin: 0 auto;

  &--sidebar {
    max-width: 100%;
    margin: 0 auto 0 235px;
  }

  .content-container {
    position: relative;
    background: #FFFFFF;
    padding: 2rem;

    &--empty {
      height: 300px;
    }

    .content-empty__message {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-size: 1.2rem;
    }
  }

  .buttons-container {
    text-align: right;

    .btn + .btn {
      margin-left: 1rem;
    }
  }

  .action__link {
    color: $gray-dark;
    text-decoration: none;

    &--disabled,
    &--disabled:hover,
    &--disabled:hover i,
    &--disabled:hover i:hover {
      color: #999 !important;
      cursor: default !important;
    }
  }
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

//=============================================//
//            PRIMENG OVERWRITE
//=============================================//

.p-nogutter {
  margin-right: 0;
  margin-left: 0;
  margin-top: 0;
}

.p-nogutter>.p-col,
.p-nogutter>[class*="p-col-"] {
  padding: 0 !important;
}

.p-grid {

  .ui-dropdown {
    width: 100%;
  }
}

.ui-messages-error {
  color: #d82222;
  border: none;
  background: none;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  text-align: left;
}

.ui-float-label {
  margin: 0.5rem 0;
  font-size: initial;

  label {
    color: #464a4c;
    padding-left: 6px;

    sup {
      color: $error;
    }
  }

  p-dropdown.ng-dirty.ng-invalid ~ label,
  p-autocomplete.ng-dirty.ng-invalid ~ label,
  p-calendar.ng-dirty.ng-invalid ~ label,
  input.ng-dirty.ng-invalid ~ label {
    color: $error;
  }

  &--textarea {
    margin-top: 0;

    textarea {
      height: 150px;
      resize: none;
      margin-top: 0;
      width: 100%;
    }

    textarea + label {
      top: 20px;
    }
  }
}

.ui-float-label > input:focus ~ label,
.ui-float-label > input.ui-state-filled ~ label,
.ui-float-label > .ui-inputwrapper-focus ~ label,
.ui-float-label > .ui-inputwrapper-filled ~ label {
  top: -.75em;
  font-size: 12px;
}

.ui-tabview{
  padding: 0;
}

.ui-form-responsive {

  .p-col-12 {

     .ui-float-label {
      margin: 1.5rem 0;
    }
  }
}

.ui-corner-all {
  border-radius: 0 !important;
}

@media screen and (max-width: 640px) {
  .ui-g,
  .ui-g-12 {

    padding-left: 0;
    padding-right: 0;
  }
}

//=============================================//

.form-control {
  margin-top: 20px;
}

@media screen and (max-width: 991px) {
  .app-container {

    &--sidebar {
      max-width: 100%;
      margin: 0 20px;
    }
  }

  .hidden-on-mobile{
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .wrapper-app {
    padding: 0.5rem;
    margin-top: $header-height;
  }
}
