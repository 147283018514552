@use '@angular/material' as mat;

// Include the common styles for Angular Material
@include mat.core();

// Define your custom theme
$primary-palette: mat.define-palette(mat.$blue-palette, 700);
$accent-palette: mat.define-palette(mat.$green-palette, A200, A100, A400);
$warn-palette: mat.define-palette(mat.$red-palette);

$theme: mat.define-light-theme((
  color: (
    primary: $primary-palette,
    accent: $accent-palette,
    warn: $warn-palette,
  ),
  typography: mat.define-typography-config(),
  density: 0,
));

// Include theme styles for core and each component used
@include mat.all-component-themes($theme);

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'c3/c3.min.css';
@import "styles/app";
@import "primeng/resources/primeng.css";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

body {
    font-size: 1rem;
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    .mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field .mdc-notched-outline__notch {
        border-right: none;
    }
    .mat-icon{
      height: auto;
      width: auto;
    }
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
