.message-container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
  z-index: 1010;

  .alert{
    padding: 0 1rem;
    border-radius: 0;
    text-align: center;
  }
}
