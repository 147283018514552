.ui-table-thead {

  .search-container {
    position: relative;
    display: inline-block;
    width: 50%;
    min-width: 300px;

    .search__icon{
      position: absolute;
      left: 8px;
      top: 6px;
      cursor: default;
    }

    .search__input {
      width: 100%;
      height: 26px;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      border: solid 1px #d9d9d9;
    }

    .search__clear{
      position: absolute;
      right: 0;
      top: 2px;
      background: none;
      border: none;
    }
  }

  .buttons-container {
    display: inline-block;
    float: right;
  }
}

.action__column{
  width: 100px !important;
  text-align: center;

  &--middle{
    width: 150px !important;
  }

  .btn{
    &--filter{
      font-size: 12px;
      padding: 0.15rem 0.5rem;
    }
  }
}

.date__column{
  width: 120px !important;
  text-align: center;
}

.action__link,
.btn.action__link{
  color: #000000;
  cursor: pointer;
  font-size: 16px;
  background: transparent;
  padding: 0;

  &:hover {
    text-decoration: underline !important;
  }

  &--disabled,
  &--disabled:hover,
  &--disabled:hover i,
  &--disabled:hover i:hover {
    color: #999 !important;
    cursor: default;
  }

  &--approve{
    color: $brand-success !important;
  }

  &--decline{
    color: $brand-danger !important;
  }

  &--text{
    font-size: 12px;
    text-decoration: none !important;
    cursor: default;

    &:hover{
      text-decoration: none !important;
    }
  }
}

.action__link + .action__link {
  display: inline-block;
  margin-left: 5px;
}
